:root {
  background-color: #464646;
  text-decoration-color: #a7a49f;
}
table {
  border-spacing: 0;
  border: 1px solid #3b3b3b;
  margin: 0 5rem 0 5rem;
}
:root body {
  background-color: #464646;
  color: #d3d3d3;
}
table tr:last-child td {
  border-bottom: 0;
}
/* table th,
table td {
  margin: auto;
  padding: 0.1rem;
  border-bottom: 1px solid #3b3b3b; */
/* border-right: 1px solid #3b3b3b;
  position: relative;
} */
/* table tr:nth-child(even) {
  background-color: #555555;
} */

.dropdown-menu.show {
  background-color: #555555;
}
.dropdown-menu.show a:hover {
  background-color: #464646;
}
.players table {
  min-width: 25%;
  margin: auto;
  text-align: center;
}
.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #464646;
}
.App .players {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #464646;
}
.home-app{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.home-header{
  color: #db9018;
  width: 100%;
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-description{
  width: 60%;
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
}
.home-list{
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}
.home-list .list{
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}
.totalRunsandPlayers{
  /* width: 100%; */
  font-size: 20px;
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  padding: 15px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  margin: 1vh;
}

.totalRunsandPlayers div{
  /* width: 100%; */
  font-size: 20px;
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  padding: 3vh;
  justify-content: center;
  align-items: center;
}

.homeTables{
  width: 60%;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  padding: 3vh;
  justify-content: center;
  align-items: center;
}
.playerTables{
  width: 60%;
  font-size:medium;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  padding: 3vh;
  justify-content: center;
  margin: auto;
  /* align-items: center; */
}
.playerTables div.home-app{
  width: 60%;
  /* align-items: center; */
}

.homeTotals{
  width: 100%;
  /* font-size: 20px; */
  /* font-family: Arial, Helvetica, sans-serif; */
  display: flex;
  /* padding: 3vh; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeVideos .h3{
  /* width: 100%; */
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
}

.homeVideos .container{
  /* width: 100%; */
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeVideos .container .row{
  /* width: 100%; */
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  padding: 1vh;
  justify-content: center;
  align-items: center;
}
a:visited {
  text-decoration: none;
  color: #db9018;
}
a:link {
  text-decoration: none;
  color: #db9018;
}
:root a:hover {
  text-decoration: underline;
  color: #db9018;
}
.Header {
  background-color: #db9018;
  /* width: 100%; */
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.title-heading {
  color: #db9018;
  width: 100%;
  /* height: 1px; */
  padding: 15px 0px 0px 0px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-dates {
  color: #bbb;
  width: 100%;
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.general-rules,
.event-rules {
  color: #db9018;
  width: 100%;
  /* height: 1px; */
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .leftSide {
  /* text-transform: uppercase; */
  width: 100%;
  height: 1px;
  /* padding: 15px 0px 0px 0px; */
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .leftSide .links {
  max-height: 80px;
}
.Header .leftSide .links a {
  text-decoration: none;
  color: white;
  font-size: 17px;
  padding: 1vw;
}
.badge {
  background-color: #353535;
  color: #22543d;
  margin-right: 4px;
  padding: 400px;
  border-radius: 120px;
  font-size: 120px;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
}
.badge a:link,
.badge a:hover,
.badge a:active,
.badge a:visited {
  color: #ddd;
}

.textbox {
  display: flex;
  /* width: 100%; */
  /* flex-basis: 100%; */
  /* justify-content: center; */
  margin: auto;
  padding: 1vh;
}

.runMedia {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
}

.HomeHeader {
  padding: 15px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  /* color: #db9018; */
}
.leaderboard-type {
  display: flex;
  flex: auto;
  /* margin: auto; */
  /* text-align: center; */
  align-items: center;
  /* justify-content: center; */
  /* color: #db9018; */
  padding-inline: 1rem;
}

.leaderboard-type .player-count{
  display: block;
  flex: auto;
  /* margin: auto; */
  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: center; */
  /* color: #db9018; */
  padding-inline: 1rem;
  float: left;
}
.quest-rank{
  display: flex;
  /* justify-content: space-between; */
  padding: 0 20rem 10px 20rem;
  font-weight: 700;
  /* color: #db9018; */
  align-items: center;
  /* float: left; */
  margin: auto;
  align-items: center;
  justify-content: center;
}
.quest-rank a{
  display: flex;
  /* justify-content: space-between; */
  padding: 0 10px 0 10px;
  font-weight: 700;
  /* color: #db9018; */
  margin: 0 20px 0 20px;
  /* float: left; */
}
.leaderboard-type .player-type{
  display: flex;
  /* white-space: pre; */
  /* flex: auto; */
  /* margin: auto; */
  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: center; */
  /* color: #db9018; */
  padding: 0 1rem 0 1rem;
  float: right;
  align-items: center;
}
.leaderboard-type a {
  padding-inline: 1rem;
  padding-inline-start: 1rem;
  /* color: #db9018; */
}

.leaderboard-type-solo{
  display: flex;
  flex: auto;
  /* margin: auto; */
  /* text-align: center; */
  align-items: center;
  /* justify-content: center; */
  /* color: #db9018; */
  padding-inline: 1rem;
  justify-content: space-between;
}

.leaderboard-type-solo .player-type{
  display: flex;
  /* white-space: pre; */
  /* flex: auto; */
  /* margin: auto; */
  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: center; */
  /* color: #db9018; */
  padding: 0 1rem 0 1rem;
  float: right;
  align-items: center;
}

.leaderboard-type-solo .player-type a{
  padding: 0 10px 0 10px;
  float: right;
  align-items: center;
}

.search-bars {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  /* color: #db9018; */
}
#homeBackground {
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.header-logo {
  padding-left: 15px;
}

.menu-items {
  display: block;
  margin: auto;
}
.run-pic {
  max-width: 1500px;
}

table {
  /* border: 0px; */
  margin: auto;
}

table {
  /* border: 0px; */
  margin: auto;
  min-width: 80%;
}

table.TableTesting {
  border: 0px;
  min-width: 0%;
}
table .BodyTesting,
table .BodyTesting .firstColumn,
table .BodyTesting .secondColumn,
table .BodyTesting .thirdColumn {
  border: 0px;
}
table .BodyTesting .firstColumn {
  /* justify-content: center; */
  width: 40%;
  /* border: 0px; */
  /* border:0px; */
  justify-content: center;

  /* align-items: flex-end; */
}
table .BodyTesting .secondColumn {
  /* justify-content: center; */
  width: 50%;
  justify-content: center;
  /* border: 0px; */
  /* border:0px; */
}
table .BodyTesting .thirdColumn {
  justify-content: right;
  width: 10%;
  align-items: right;
  /* border: 0px; */
  /* border:0px; */
}
hr.solid {
  border: 1px thin #bbb;
}
.HomeInfo {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  padding: 2rem;
}

.post {
  align-items: center;
}

.Solo {
  display: flex;
  align-items: center;
  text-align: center;
}

.myTest {
  padding: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.records {
  /* padding: 5px; */
  /* display: grid; */
  /* align-items: center; */
  /* text-align: center; */
  /* justify-content: center; */
}

/* .records .TableTesting .BodyTesting td {
  display: flex;
  align-items: left;
  justify-content: left;
} */

.records .TableTesting .BodyTesting {
  /* display: flex; */
  justify-content: left;
  align-items: left;
  text-align: left;
}
.records .TableTesting {
  margin-left: auto;
  margin-right: auto;
  /* white-space: pre-line; */
  /* display: flex; */
}

img.avatar {
  object-fit: cover;
  border-radius: 50%;
  width: 75px;
  height: 75px;
}
img.avatar2 {
  object-fit: cover;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  display: flex;
  margin: auto;
}
.players table tbody td {
  padding: 5px 0 0 0;
}
.recentTable {
  margin: 0 5vw 0 5vw;
}
.record_leaders {
  display: flex;
  margin: auto;
  text-align: center;
}
.player_name {
  font-size: 2rem;
}
.record_leader {
  padding-top: 2vh;
  padding-inline: 4vw;
  color: #212529;
}

:root .MuiTableCell-body {
  color: #d3d3d3;
}
:root .MuiTableCell-root {
  display: table-cell;
  padding: 6px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 0px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.02em;
  vertical-align: inherit;
}
:root table th,
:root table td {
  margin: auto;
  padding: 0rem;
  position: relative;
}

:root h3 {
  justify-content: center;
  display: flex;
}
:root .MuiToolbar-root {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 1rem;
}
